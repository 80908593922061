import {createAsyncThunk} from '@reduxjs/toolkit';
import {hideLoading, showLoading} from '@Libraries/loading-toast-library';
import type {GridStorageTypes, StateProps} from '@Components/base-grid/base-grid-reducer';
import {deleteGridItems, updateGridItem} from '@Components/base-grid/base-grid-reducer';
import {findGridItem, GRID_ID, castIdToStringForBaseGrid} from '@Libraries/email-marketing-campaigns-audience-library';
import type {GridEmailCampaignAudienceItemEmailsData} from '@Components/base-grid/components/grid-email-campaign-audience-item';
import type {Audience} from '@Components/email-marketing-campaign-audience/email-marketing-campaign-audience-reducer';

export interface GetEmailsByMailingListIdParams {
  idMailingList: number;
  limit: number;
  offset: number;
  doFetchEmailsCount: boolean;
  deletedFilter: number;
}

interface GetEmailsForCSVParams {
  idMailingList: number;
}

interface MailingListViewedParams {
  idMailingList: number;
}

export interface DeleteAudienceParams {
  idMailingList: number;
  audience: GridEmailCampaignAudienceItemEmailsData;
  index: number;
}

export interface UpdateMailingListNameParams {
  idMailingList: number;
  newName: string;
  currentName: string;
}

export interface UpdatePhoneNumberForAudienceInMailingListParams {
  idAudience: number;
  phoneNumber: string;
}

export interface UpdateFullNameForAudienceInMailingListParams {
  idAudience: number;
  fullName: string;
}

export interface UpdateInfoForAudienceInMailingListParams {
  idAudience: number;
  fullName?: string;
  phoneNumber?: string;
}

interface UpdateMailingListNameInGridParams {
  itemId: string;
  newName: string;
}

interface DuplicateMailingListParams {
  idMailingList: string;
}

interface GetEmailsByMailingListIdPayload {
  idMailingList: number;
  name: string;
  audiences: Audience[];
  count: number;
  isSpam: boolean;
  lastViewed: number;
}

export const getEmailsByMailingListId = createAsyncThunk('getEmailsByMailingListId', async (data: GetEmailsByMailingListIdParams): Promise<GetEmailsByMailingListIdPayload> => {
  return window.PMW.readLocal('emailmarketing/getEmailsForAudience', data);
});

export const duplicateMailingList = createAsyncThunk('duplicateMailingList', async (data: DuplicateMailingListParams) => {
  showLoading('duplicatingMailingList', {text: 'Duplicating audience'});
  const response = await window.PMW.readLocal('emailmarketing/duplicateMailingList', data);
  hideLoading('duplicatingMailingList');
  return response;
});

export const deleteEmail = createAsyncThunk('deleteEmail', async (data: DeleteAudienceParams) => {
  return window.PMW.writeLocal('emailmarketing/deleteEmailFromMailingList', {
    idMailingList: data.idMailingList,
    email: data.audience.email,
  });
});

export const mailingListViewed = createAsyncThunk('mailingListViewed', async (data: MailingListViewedParams) => {
  return window.PMW.writeLocal('emailmarketing/updateMailingListLastViewed', {
    idMailingList: data.idMailingList,
  });
});

export const updatePhoneNumberForMailingListAudience = createAsyncThunk(
  'updatePhoneNumberForMailingListAudience',
  async (data: UpdatePhoneNumberForAudienceInMailingListParams) => {
    return window.PMW.writeLocal('emailmarketing/updatePhoneNumberForMailingListAudience', {
      idAudience: data.idAudience,
      phoneNumber: data.phoneNumber,
    });
  }
);

export const updateFullNameForMailingListAudience = createAsyncThunk('updateFullNameForMailingListAudience', async (data: UpdateFullNameForAudienceInMailingListParams) => {
  return window.PMW.writeLocal('emailmarketing/updateFullNameForMailingListAudience', {
    idAudience: data.idAudience,
    fullName: data.fullName,
  });
});

export const updateInfoForMailingListAudience = createAsyncThunk('updateInfoForMailingListAudience', async (data: UpdateInfoForAudienceInMailingListParams) => {
  return window.PMW.writeLocal('emailmarketing/updateInfoForMailingListAudience', {
    idAudience: data.idAudience,
    fullName: data.fullName,
    phoneNumber: data.phoneNumber,
  });
});

export const updateMailingListName = createAsyncThunk('updateMailingListName', async (data: UpdateMailingListNameParams, {getState, dispatch, rejectWithValue}) => {
  const {grids} = getState() as {grids: StateProps};

  const grid = grids.gridsHashmap[GRID_ID];
  let response;

  try {
    updateMailingListNameInGrid(dispatch, grid, {
      itemId: castIdToStringForBaseGrid(data.idMailingList),
      newName: data.newName,
    });

    response = await window.PMW.writeLocal('emailmarketing/updateMailingListName', {
      idMailingList: data.idMailingList,
      name: data.newName,
    });
  } catch (err) {
    updateMailingListNameInGrid(dispatch, grid, {
      itemId: castIdToStringForBaseGrid(data.idMailingList),
      newName: data.currentName,
    });
    return rejectWithValue(err);
  }

  return response;
});

export const deleteMailingList = createAsyncThunk('deleteMailingList', async (data: GetEmailsForCSVParams, {getState, dispatch, rejectWithValue}) => {
  const {grids} = getState() as {grids: StateProps};

  const grid = grids.gridsHashmap[GRID_ID];
  showLoading('delete-list');

  try {
    //codeReviewError: ESLint warning. Do we need to do anything about these warnings? I see other ESLint warnings in this file as well
    const response = await window.PMW.writeLocal('emailmarketing/deleteMailingList', data);
    const gridItem = findGridItem(grid, data.idMailingList);

    if (gridItem) {
      dispatch(
        deleteGridItems({
          gridId: GRID_ID,
          gridSelectionGroupId: GRID_ID,
          itemIdsToDelete: [gridItem.id],
        })
      );
    }

    hideLoading('delete-list');
    return response;
  } catch (err) {
    rejectWithValue(err);
    hideLoading('delete-list');
  }
});

const updateMailingListNameInGrid = (dispatch: any, grid: GridStorageTypes, data: UpdateMailingListNameInGridParams) => {
  if (grid) {
    dispatch(
      updateGridItem({
        gridId: GRID_ID,
        itemId: data.itemId,
        changes: {
          name: data.newName,
        },
      })
    );
  }
};
