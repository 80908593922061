import type {CSSProperties, ReactElement} from 'react';
import React from 'react';
import type {GridItemStorage} from '@Components/base-grid/components/grid-item';
import {GRID_ITEM_TYPE, GridItem} from '@Components/base-grid/components/grid-item';
import {GridClipartItem} from '@Components/base-grid/components/grid-clipart-item';
import {GridShapeItem} from '@Components/base-grid/components/grid-shape-item';
import {GridAnimatedStickerItem} from '@Components/base-grid/components/grid-animated-sticker-item';
import {GridUserImageItem} from '@Components/base-grid/components/grid-user-image-item';
import {repoImageThumbURL} from '@Libraries/graphic-item-image-library';
import {GridUserVideoItem} from '@Components/base-grid/components/grid-user-video-item';
import {repoVideoThumbImageURL, repoVideoThumbURL} from '@Libraries/user-video-library';
import {GridUserAudioItem} from '@Components/base-grid/components/grid-user-audio-item';
import {getAudioUrl, repoAudioThumbImageURL} from '@Libraries/user-audio-library';
import {GridImageItem} from '@Components/base-grid/components/grid-image-item';
import {GridButtonItem} from '@Components/base-grid/components/grid-button-item';
import {GridStockVideoItem} from '@Components/base-grid/components/grid-stock-video-item';
import {GridStockImageItem} from '@Components/base-grid/components/grid-stock-image-item';
import {GridCollectionItem} from '@Components/base-grid/components/grid-collection-item';
import {GridStockAudioItem} from '@Components/base-grid/components/grid-stock-audio-item';
import {GridEmailCampaignAudienceItem} from '@Components/base-grid/components/grid-email-campaign-audience-item';
import {GridEmailSignupFormItem} from '@Components/base-grid/components/grid-email-signup-form-item';
import {GridSkeletonItem} from '@Components/base-grid/components/grid-skeleton-item';
import {GridFancytextItem} from '@Components/base-grid/components/grid-fancytext-item/grid-fancytext-item';
import {
  getPMWStockAnimatedStickerThumbURL,
  getPMWStockEmojiThumbURL,
  getPMWStockIconThumbURL,
  getPMWStockImageThumbURL,
  getPMWStockShapeThumbURL,
  getPMWStockStillStickerThumbURL,
} from '@Libraries/pmw-stock-media-library';
import {PMWStockItemType} from '@Panels/manage-pmw-stock-panels/manage-pmw-stock-panels-types';
import type {GridPMWStockID} from '@Components/base-grid/components/grid-pmwstockmedia-item/grid-pmw-stock-media-item';
import {GridPMWStockMediaItem} from '@Components/base-grid/components/grid-pmwstockmedia-item/grid-pmw-stock-media-item';
import {GridIconItem} from '@Components/base-grid/components/grid-icon-item';
import type {GridMediaItemTag} from '@Components/base-grid/components/grid-media-item';
import {GridStillStickerItem} from '@Components/base-grid/components/grid-still-sticker-item';
import {GridGenericButtonItem} from '@Components/base-grid/components/grid-generic-button-item/grid-generic-button-item';

export interface BaseGridProps {
  /**
   * The id associated with the grid which keeps track of all the items.
   */
  gridId: string;
  /**
   * The id of selection group this grid is part of.
   *
   * This is used when you want to keep track of selected items accross multiple grids. For that case you would specify the same
   * gridSelectionGroupId for all these grids.
   *
   * If not specified, defaults to the group id hence creating a unique selection group for the grid.
   */
  gridSelectionGroupId?: string;
  gridItemAspectRatio?: number;
  gridItemMaxWidth?: number;
  className?: string;
  scrollingContainer?: HTMLDivElement | null;
  gridTitleClasses?: string;
  gridStyle?: CSSProperties;
  gridContainerStyle?: CSSProperties;
  gridTitle?: string;
  onSelectedItemsChanged?: Function;
  /**
   * 0 value for it means no limit on amount of selected items
   */
  maxSelectionNumber?: number;
  excludeItemWithExtension?: string;
  makeContentScrollable?: boolean;

  attachScrollListener?(cb: () => void): void;

  /**
   * If specified the default handler that selects/unselects an item doesn't fire. Instead this is called
   */
  customOnGridItemClicked?(item: GridItemStorage, isDoubleClick: boolean): void;

  onScrolledToBottom?(): void;

  gridRef?(ref: HTMLDivElement | null): void;
  gridClassName?: string;
  doSetGridInlineStyles?: boolean;
}

interface GetGridItemForStoredDataParams {
  paddedThumb?: boolean;
  item: GridItemStorage;
  gridId: string;
  gridSelectionGroupId: string;
  style: CSSProperties;
  showSelectionNumber: boolean;
  showHoverOverlay?: boolean;
  hasTransparency?: boolean;
  className: string;
  containerClassName?: string;
  isSmallItem?: boolean;
  onClicked(e: React.MouseEvent, isDoubleClick: boolean): void;
}

export function getGridItemForStoredData(params: GetGridItemForStoredDataParams): ReactElement | null {
  const commonProps = {
    key: params.item.id,
    id: params.item.id,
    gridId: params.gridId,
    gridSelectionGroupId: params.gridSelectionGroupId,
    isSmallItem: params.isSmallItem,
    className: params.className,
    style: params.style,
    showHoverOverlay: params.showHoverOverlay,
    showSelectionNumber: params.showSelectionNumber,
    onClicked: (e: React.MouseEvent, isDoubleClick: boolean): void => {
      return params.onClicked(e, isDoubleClick);
    },
    isSkeletion: false,
    hasTransparency: params.hasTransparency,
    tag: (params.item.tag as GridMediaItemTag) ?? undefined,
    containerClassName: (params.item.containerClassName as string) ?? undefined,
  };

  const getThumbSrcFromStockType = (stockType: PMWStockItemType): string => {
    switch (stockType) {
      case PMWStockItemType.ANIMATED_STICKER:
        return getPMWStockAnimatedStickerThumbURL(params.item.hashedFilename);
      case PMWStockItemType.SHAPE:
        return getPMWStockShapeThumbURL((params.item.idPMWStock as GridPMWStockID).idPMWStockShape as string);
      case PMWStockItemType.ICON:
        return getPMWStockIconThumbURL(params.item.hashedFilename as string);
      case PMWStockItemType.STILL_STICKER:
        return getPMWStockStillStickerThumbURL(params.item.hashedFilename as string);
      case PMWStockItemType.IMAGE:
        return getPMWStockImageThumbURL(params.item.hashedFilename as string);
      case PMWStockItemType.EMOJI:
        return getPMWStockEmojiThumbURL(params.item.hashedFilename as string);
      default:
        throw new Error(`PMWStockItemType not handled: ${stockType}`);
    }
  };

  switch (params.item.type) {
    case GRID_ITEM_TYPE.CLIPART:
      return <GridClipartItem {...commonProps} paddedThumb={params.paddedThumb} thumbSrc={params.item.thumbSrc} />;

    case GRID_ITEM_TYPE.PMW_STOCK_SHAPE:
      return <GridShapeItem {...commonProps} thumbSrc={getPMWStockShapeThumbURL(params.item.hashedFilename)} />;

    case GRID_ITEM_TYPE.PMW_STOCK_ANIMATED_STICKER:
      return <GridAnimatedStickerItem {...commonProps} videoThumbSrc={getPMWStockAnimatedStickerThumbURL(params.item.hashedFilename)} />;

    case GRID_ITEM_TYPE.USER_IMAGE:
      return (
        <GridUserImageItem
          {...commonProps}
          thumbSrc={repoImageThumbURL(params.item.hashedFilename, params.item.extension)}
          uploaderName={params.item?.uploaderName}
          gridItemWidth={params.item?.gridItemWidth}
          isHidden={params.item?.isHidden}
        />
      );

    case GRID_ITEM_TYPE.USER_VIDEO:
      return (
        <GridUserVideoItem
          {...commonProps}
          containerClassName={params.containerClassName}
          imgThumbSrc={repoVideoThumbImageURL(params.item.hashedFilename, params.item.extension)}
          videoThumbSrc={repoVideoThumbURL(params.item.hashedFilename, params.item.extension)}
          uploaderName={params.item?.uploaderName}
          duration={params.item?.duration}
          gridItemWidth={params.item?.gridItemWidth}
          isHidden={params.item?.isHidden}
        />
      );
    case GRID_ITEM_TYPE.USER_AUDIO:
      return (
        <GridUserAudioItem
          {...commonProps}
          imgthumbSrc={repoAudioThumbImageURL(params.item.hashedFilename)}
          audioSrc={getAudioUrl(params.item.hashedFilename)}
          uploaderName={params.item?.uploaderName}
          duration={params.item?.duration}
          name={params.item?.name}
          hashedFilename={params.item?.hashedFilename}
          gridItemWidth={params.item?.gridItemWidth}
          isHidden={params.item?.isHidden}
        />
      );

    case GRID_ITEM_TYPE.PMW_STOCK_STILL_STICKER:
      return <GridStillStickerItem {...commonProps} thumbSrc={getPMWStockStillStickerThumbURL(params.item.hashedFilename)} />;

    case GRID_ITEM_TYPE.PMW_STOCK_IMAGE:
      return <GridImageItem {...commonProps} thumbSrc={getPMWStockImageThumbURL(params.item.hashedFilename)} />;

    case GRID_ITEM_TYPE.PMW_STOCK_ICON:
      return <GridIconItem {...commonProps} thumbSrc={getPMWStockIconThumbURL(params.item.hashedFilename)} />;

    case GRID_ITEM_TYPE.IMAGE:
      return <GridImageItem {...commonProps} thumbSrc={params.item.thumbSrc} paddedThumb={params.paddedThumb} />;

    case GRID_ITEM_TYPE.FANCYTEXT:
      // todo: replace thumbSrc prop with a call to a function getThumbSrc once all predefined fancytexts are finalised by Zohair
      //  (getThumbSrc will generate a URL by making a call to the controller at the endpoint Fancytextrender.output render)
      return <GridFancytextItem {...commonProps} thumbSrc="https://d1csarkz8obe9u.cloudfront.net/texteffects/small/32.jpg?" paddedThumb={params.paddedThumb} />;

    case GRID_ITEM_TYPE.BUTTON:
      return (
        <GridButtonItem
          {...commonProps}
          iconSize={params.item.iconSize}
          icon={params.item.icon}
          text={params.item.text}
          className={params.item.className}
          flexDirection={params.item.flexDirection}
          applyCustomStyles={params.item.applyCustomStyles ?? true}
          containerClassName={params.item.containerClassName}
        />
      );

    case GRID_ITEM_TYPE.GENERIC_BUTTON:
      return <GridGenericButtonItem {...commonProps} onClicked={params.item.onClicked} buttonProps={params.item.buttonProps} />;

    case GRID_ITEM_TYPE.STOCK_VIDEO:
      return (
        <GridStockVideoItem
          {...commonProps}
          containerClassName={params.containerClassName}
          videoThumbSrc={params.item.videoThumbSrc}
          imgThumbSrc={params.item.imgThumbSrc}
          isUHD={params.item.isUHD}
          duration={params.item.duration}
          isLoading={params.item.isLoading}
        />
      );

    case GRID_ITEM_TYPE.STOCK_IMAGE:
      return <GridStockImageItem {...commonProps} paddedThumb={params.paddedThumb} thumbSrc={params.item.thumbSrc} isLoading={params.item.isLoading} />;

    case GRID_ITEM_TYPE.COLLECTION:
      return <GridCollectionItem {...commonProps} isShared={params.item.isShared} collectionName={params.item.collectionName} multiMediaType={params.item.multiMediaType} />;

    case GRID_ITEM_TYPE.STOCK_AUDIO:
      return (
        <GridStockAudioItem
          {...commonProps}
          gridItemHeight={params.item.gridItemHeight}
          gridItemWidth={params.item.gridItemWidth}
          audioSrc={params.item.audioSrc}
          imgthumbSrc={params.item.imgThumbSrc}
          name={params.item.name}
          duration={params.item.duration}
        />
      );

    case GRID_ITEM_TYPE.EMAIL_CAMPAIGN_AUDIENCE:
      return <GridEmailCampaignAudienceItem {...commonProps} id={parseInt(params.item.id)} />;

    case GRID_ITEM_TYPE.EMAIL_SIGNUP_FORM:
      return <GridEmailSignupFormItem {...commonProps} id={params.item.id} />;

    case GRID_ITEM_TYPE.SKELETON:
      return (
        <GridSkeletonItem key={params.item.id} styles={params.style} useFixedDimensions={params.item.useFixedDimensions} width={params.item.width} height={params.item.height} />
      );

    case GRID_ITEM_TYPE.CUSTOM:
      return <GridItem {...commonProps} {...params.item} />;

    case GRID_ITEM_TYPE.PMW_STOCK_MEDIA:
      return (
        <GridPMWStockMediaItem
          {...commonProps}
          id={params.item.id}
          thumbSrc={getThumbSrcFromStockType(params.item.stockType)}
          paddedThumb={params.paddedThumb}
          keywords={params.item.keywords}
          isEdited={params.item.isEdited}
          isDeleted={params.item.isDeleted}
          stockType={params.item.stockType}
          scrollToItem={params.item.scrollToItem}
          adminScore={params.item.adminScore}
        />
      );

    default:
      console.error(`Unhandled Grid item type: ${params.item.type}`);
      return null;
  }
}
