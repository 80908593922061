import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import {initMenuItem} from './user-menu-panel-helper';
import type {MenuItemData, MenuItemIcon, MenuItemTag} from './user-menu-panel.types';
import {POS_LOADING_STATES} from './user-menu-panel.types';

interface InitItemsDataProps {
  unlinkedItemIds: Array<string>;
  allIcons: Array<MenuItemIcon>;
  isPosAccountLinked: boolean;
  offset: number;
  selectedLayout: string;
  categories: Array<MenuItemTag>;
}

interface ResetAllItemsProps {
  unlinkedItemIds: Array<string>;
  selectedLayout: string;
}

interface StateProps {
  isPosSectionActive: boolean;
  inEditMode: boolean;
  activeItem: MenuItemData;
  selectedLayout: string;
  unlinkedItemIds: Array<string>;
  allIcons: Array<MenuItemIcon>;
  posLoadingState: number;
  isPosAccountLinked: boolean;
  userItemsLoaded: boolean;
  selectionSectionSynced: boolean;
  offset: number;
  posCategories: Array<MenuItemTag>;
  categories: Array<MenuItemTag>;
  searchingWithTags: boolean;
  loadingToken: string;
}

const initialState: StateProps = {
  selectedLayout: 'layout-10',
  isPosSectionActive: false,
  inEditMode: false,
  unlinkedItemIds: [],
  allIcons: [],
  activeItem: initMenuItem(),
  posLoadingState: POS_LOADING_STATES.DEFAULT,
  isPosAccountLinked: false,
  userItemsLoaded: false,
  selectionSectionSynced: true,
  offset: 0,
  posCategories: [],
  categories: [],
  searchingWithTags: false,
  loadingToken: '',
};

const menuSlice = createSlice({
  name: 'menuModal',
  initialState,
  reducers: {
    setPosLoadingState: (state, action: PayloadAction<number>) => {
      state.posLoadingState = action.payload;
      if (action.payload !== POS_LOADING_STATES.DEFAULT) {
        state.isPosSectionActive = true;
      }
    },
    onLoggedIntoPosAccount: (state, action: PayloadAction) => {
      state.posLoadingState = POS_LOADING_STATES.LOADING_ITEMS;
      state.isPosAccountLinked = true;
    },
    setIsPosSectionActive: (state, action: PayloadAction<boolean>) => {
      state.isPosSectionActive = action.payload;
      if (!action.payload) {
        state.posLoadingState = POS_LOADING_STATES.DEFAULT;
      }
    },
    setSelectedLayout: (state, action: PayloadAction<string>) => {
      state.selectedLayout = action.payload;
    },
    syncSelectedSection: (state, action: PayloadAction<boolean>) => {
      state.selectionSectionSynced = action.payload;
    },
    initItemsData: (state, action: PayloadAction<InitItemsDataProps>) => {
      state.unlinkedItemIds = action.payload.unlinkedItemIds;
      state.allIcons = action.payload.allIcons;
      state.userItemsLoaded = true;
      state.isPosAccountLinked = action.payload.isPosAccountLinked;
      state.offset = action.payload.offset;
      state.selectedLayout = action.payload.selectedLayout;
      state.categories = action.payload.categories;
    },
    resetMenuItems: (state, action: PayloadAction<ResetAllItemsProps>) => {
      state.unlinkedItemIds = action.payload.unlinkedItemIds;
      state.inEditMode = false;
      state.isPosSectionActive = false;
      state.activeItem = initMenuItem();
      state.selectedLayout = action.payload.selectedLayout;
      updateAllCategoriesSelection(state);
    },
    toggleEditMode: (state, action: PayloadAction<MenuItemData | null>) => {
      state.inEditMode = !!action.payload;
      state.activeItem = action.payload ? action.payload : initMenuItem();
    },
    posAccountUnlinked: (state, action: PayloadAction) => {
      state.isPosAccountLinked = false;
      state.isPosSectionActive = false;
      state.posLoadingState = POS_LOADING_STATES.DEFAULT;
    },
    setUnlinkedItems: (state, action: PayloadAction<Array<string>>) => {
      state.unlinkedItemIds = action.payload;
    },
    setItemsOffset: (state, action: PayloadAction<number>) => {
      state.offset = action.payload;
    },
    setUserItemsLoaded: (state, action: PayloadAction<boolean>) => {
      state.userItemsLoaded = action.payload;
    },
    linkItem: (state, action: PayloadAction<string>) => {
      const unlinkedIdsArray = state.unlinkedItemIds;
      const index = unlinkedIdsArray.indexOf(action.payload);
      if (index === -1) {
        return;
      }

      unlinkedIdsArray.splice(index, 1);
      state.unlinkedItemIds = unlinkedIdsArray;
    },
    updateCategoriesSelection: (state, action: PayloadAction<string>) => {
      const allCategories = [...state.categories];
      state.categories = allCategories.map((category) => {
        return {
          ...category,
          isSelected: category.id === action.payload ? !category.isSelected : category.isSelected,
        };
      });
    },
    setIsSearchingWithTags: (state, action: PayloadAction<boolean>) => {
      state.searchingWithTags = action.payload;
    },
    updatePosCategories: (state, action: PayloadAction<Array<MenuItemTag>>) => {
      state.posCategories = action.payload.map((category) => {
        return {
          ...category,
          isSelected: false,
        };
      });
    },
    updatePosCategoriesSelection: (state, action: PayloadAction<string>) => {
      const allCategories = [...state.posCategories];
      state.posCategories = allCategories.map((category) => {
        return {
          ...category,
          isSelected: category.id === action.payload ? !category.isSelected : category.isSelected,
        };
      });
      state.searchingWithTags = true;
    },
    removeAllFilters: (state) => {
      state.categories = state.categories.map((category) => {
        return {
          ...category,
          isSelected: false,
        };
      });
    },
    updateLoadingToken: (state, action: PayloadAction<string>) => {
      state.loadingToken = action.payload;
    },
  },
});

const updateAllCategoriesSelection = (state: StateProps): void => {
  const allCategories: Array<MenuItemTag> = [...state.categories];
  state.categories = allCategories.map((category) => {
    return {
      ...category,
      isSelected: false,
    };
  });
};

export const {
  setPosLoadingState,
  setSelectedLayout,
  setIsPosSectionActive,
  syncSelectedSection,
  resetMenuItems,
  initItemsData,
  toggleEditMode,
  posAccountUnlinked,
  onLoggedIntoPosAccount,
  setUnlinkedItems,
  setItemsOffset,
  setUserItemsLoaded,
  linkItem,
  updateLoadingToken,
  updateCategoriesSelection,
  setIsSearchingWithTags,
  updatePosCategories,
  updatePosCategoriesSelection,
  removeAllFilters,
} = menuSlice.actions;
export const menusReducer = menuSlice.reducer;
