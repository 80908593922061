import type {KeyboardEvent, ReactElement, Ref} from 'react';
import React, {useEffect, useRef, useState} from 'react';
import {InputFieldText, InputFieldTextType} from '@Components/input-field-v2/components/input-field-text';
import {Icon} from '@Components/icon-v2';
import {InputFieldControl} from '@Components/input-field-v2/components/input-field-control';
import {IconSize} from '@Components/icon-v2/icon.types';
import type {InputFieldControls, InputFieldHandlers, InputFieldNumberProperties, InputType} from '@Components/input-field-v2/input-field.types';
import {DEFAULT_NUMBER_OF_ROWS, InputFieldSize, InputFieldState, InputFieldType} from '@Components/input-field-v2/input-field.types';
import styles from './input-field.module.scss';

export interface InputFieldProps extends InputFieldHandlers, InputFieldNumberProperties {
  id?: string;
  className?: string;
  input?: InputType;
  placeholder?: string;
  inputClassName?: string;
  iconClassName?: string;
  label?: string;
  elementTitle?: string;
  size?: InputFieldSize;
  information?: string;
  state?: InputFieldState;
  isDisabled?: boolean;
  inputType?: InputFieldType;
  controls?: InputFieldControls;
  hasIcon?: string;
  maxLength?: number;
  isReadOnly?: boolean;
  allowOnChangeWithKeys?: boolean;
  fireOnChangeOnBackspace?: boolean;
  isControlled?: boolean;
  stopClickPropagation?: boolean;
  autoFocus?: boolean;
  onKeyUp?: (e: KeyboardEvent) => void;
  selectOnFocus?: boolean;
  retainFocusOnTab?: boolean;
  enforceMaxLength?: boolean;
  useEmptyPlaceholder?: boolean;

  /**
   * Text area
   */
  isMultiline?: boolean;
  maxNumberOfRows?: number;
  typingStoppedTimeout?: number;
  isValueBlank?: boolean;
  doBlurOnSubmit?: boolean;
}

export const InputField = React.forwardRef(
  (
    {
      id = '',
      input = '',
      placeholder = '',
      className = '',
      inputClassName = '',
      iconClassName = '',
      size = InputFieldSize.SMALL,
      isDisabled = false,
      label = '',
      elementTitle = '',
      information = '',
      state = InputFieldState.DEFAULT,
      hasIcon = '',
      maxLength = 200,
      inputType = InputFieldType.TEXT,
      isReadOnly = false,
      onInputBlur = (): void => {},
      onInputFocus = (): void => {},
      onInputSubmit = (): void => {},
      onArrowUpKeyDown = (): void => {},
      onKeyUp = (): void => {},
      onArrowDownKeyDown = (): void => {},
      onBackSpaceKeyDown = (): void => {},
      onInputChange = (): void => {},
      onInputChangeEnded = (): void => {},
      allowOnChangeWithKeys = false,
      fireOnChangeOnBackspace = true,
      min = 0,
      max = 100,
      step = 1,
      isMultiline = false,
      maxNumberOfRows = DEFAULT_NUMBER_OF_ROWS,
      isControlled = true,
      stopClickPropagation = false,
      typingStoppedTimeout = 600,
      isValueBlank = false,
      selectOnFocus = false,
      retainFocusOnTab = false,
      doBlurOnSubmit = true,
      enforceMaxLength = true,
      useEmptyPlaceholder = false,
      ...props
    }: InputFieldProps,
    ref: Ref<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    useEffect(() => {
      if (isMultiline && props.controls) {
        throw new Error(`Controls are not supported for textarea / multiline`);
      }
    }, [isMultiline]);

    const timer = useRef<number | undefined>();
    const [inputState, setInputState] = useState<InputType>(input);

    const getSizeClasses = (): string => {
      if (isMultiline) {
        return '';
      }
      switch (size) {
        case InputFieldSize.XSMALL:
          return styles.xsmall;
        case InputFieldSize.SMALL:
          return styles.small;
        case InputFieldSize.MEDIUM:
          return styles.medium;
        default:
          return styles.small;
      }
    };

    const getInputClasses = (): string => {
      const classes = [inputClassName, 'hover-transition-all', styles.input, getInputPadding(), getTextClasses()];

      if (isMultiline) {
        classes.push(styles.textArea);
      } else {
        classes.push(styles.overflowContent);
      }

      if (isDisabled) {
        classes.push(styles.disabled);
      }

      if (state) {
        classes.push(getClassesForStates());
      }

      if (inputType === InputFieldType.SEARCH) {
        classes.push(styles.searchType);
      }

      return classes.join(' ');
    };

    const getTextClasses = (): string => {
      switch (size) {
        case InputFieldSize.XSMALL:
          return 'body-xxs';
        case InputFieldSize.SMALL:
          return 'body-xs';
        case InputFieldSize.MEDIUM:
          return 'body-s';
        default:
          return '';
      }
    };

    const getNumberOfControls = (): number | undefined => {
      if (props.controls) {
        if (props.controls.showDefaultControls) {
          return props.controls.showSingleDefaultControlOnly ? 1 : 2;
        }
        return props.controls.customControls?.length;
      }
      return 0;
    };

    const getInputPadding = (): string => {
      const numControls = getNumberOfControls();
      let classes = '';
      switch (size) {
        case InputFieldSize.XSMALL:
        case InputFieldSize.SMALL:
          classes = `${hasIcon ? styles.paddingWithLeftIconSmall : styles.paddingLeftSmall} ${styles.paddingTopAndBottomSmall} `;
          if (numControls === 1) {
            classes += styles.paddingRightSmallWithControls;
          } else if (numControls === 2) {
            classes += styles.paddingRightSmallWithMultipleControls;
          } else {
            classes += styles.paddingRightSmall;
          }
          return classes;
        case InputFieldSize.MEDIUM:
          classes = `spacing-p-t-3 ${styles.bottomInputPadding} ${hasIcon ? styles.paddingWithLeftIconMedium : 'spacing-p-l-3'} `;
          if (numControls === 1) {
            classes += 'spacing-p-r-13';
          } else if (numControls === 2) {
            classes += styles.paddingRightMediumWithControls;
          } else {
            classes += 'spacing-p-r-3';
          }
          return classes;
        default:
          return classes;
      }
    };

    const getLabelMargin = (): string => {
      switch (size) {
        case InputFieldSize.XSMALL:
        case InputFieldSize.SMALL:
          return styles.labelSmall;
        case InputFieldSize.MEDIUM:
          return 'spacing-m-b-2';
        default:
          return '';
      }
    };

    const getInformationTextType = (): InputFieldTextType => {
      if (information && state) {
        switch (state) {
          case InputFieldState.DEFAULT:
            return InputFieldTextType.DEFAULT_INFORMATION_TEXT;
          case InputFieldState.ERROR:
            return InputFieldTextType.ERROR_INFORMATION_TEXT;
          case InputFieldState.SUCCESS:
            return InputFieldTextType.SUCCESS_INFORMATION_TEXT;
          default:
            throw new Error('Invalid Input Field State');
        }
      }
      return InputFieldTextType.DEFAULT_INFORMATION_TEXT;
    };

    const getClassesForStates = (): string => {
      if (state === InputFieldState.ERROR) {
        return styles.error;
      }
      if (state === InputFieldState.SUCCESS) {
        return styles.success;
      }
      return '';
    };

    const getIconSize = (): IconSize => {
      switch (size) {
        case InputFieldSize.XSMALL:
        case InputFieldSize.SMALL:
          return IconSize.SIZE_ICON_16;
        case InputFieldSize.MEDIUM:
          return IconSize.SIZE_ICON_20;
        default:
          return IconSize.SIZE_ICON_16;
      }
    };

    const getIconClasses = (): string => {
      const classes = ['content-disabled', styles.leftIcon, iconClassName];
      switch (size) {
        case InputFieldSize.XSMALL:
        case InputFieldSize.SMALL:
          classes.push(styles.paddingLeftSmall);
          break;
        case InputFieldSize.MEDIUM:
          classes.push('spacing-p-l-3');
          classes.push(styles.paddingRightIconMedium);
          break;
        default:
          return '';
      }

      return classes.join(' ');
    };

    const getContainerClassesForParentContainer = (): string => {
      return [className, 'flex-row-align-center', styles.parentContainer, getSizeClasses()].join(' ');
    };

    const getDefaultPlaceholders = (): string => {
      if (isValueBlank) {
        return '';
      }
      if (isMultiline) {
        return window.i18next.t('pmwjs_add_text_2');
      }
      switch (inputType) {
        case InputFieldType.NUMBER:
          return window.i18next.t('pmwjs_enter_number');
        case InputFieldType.PASSWORD:
          return window.i18next.t('pmwjs_password');
        case InputFieldType.EMAIL:
          return window.i18next.t('pmwjs_email_address_placeholder');
        case InputFieldType.TEXT:
          return window.i18next.t('pmwjs_add_text_2');
        default:
          return '';
      }
    };

    const getLabel = (): ReactElement | null => {
      if (label) {
        return <InputFieldText size={size} className={getLabelMargin()} text={label} type={InputFieldTextType.LABEL_TEXT} isBold isDisabled={isDisabled} />;
      }

      return null;
    };

    const getInformationField = (): ReactElement | null => {
      if (information) {
        return <InputFieldText className={styles.informationField} text={information} type={getInformationTextType()} size={size} />;
      }

      return null;
    };

    const getIconForInputField = (): ReactElement | null => {
      if (hasIcon) {
        return <Icon icon={hasIcon} size={getIconSize()} className={getIconClasses()} />;
      }

      return null;
    };

    const getControls = (): ReactElement | null => {
      if (props.controls) {
        return (
          <InputFieldControl
            showDefaultControls={props.controls.showDefaultControls}
            size={size}
            showSingleDefaultControlOnly={props.controls.showSingleDefaultControlOnly}
            isDisabled={isDisabled}
            customControls={props.controls.customControls}
            onClick={(clickedId, e): void => {
              if (props.controls?.onClick) {
                props.controls.onClick(clickedId, e);
              }
            }}
          />
        );
      }

      return null;
    };

    const handleTypingStoppedHandler = (value: InputType): void => {
      if (timer) {
        clearTimeout(timer.current);
      }

      timer.current = window.setTimeout(() => {
        onInputChangeEnded(value);
      }, typingStoppedTimeout);
    };

    const handleOnKeyDown = (e: KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
      if (e.key === 'Enter') {
        onInputSubmit();
        if (doBlurOnSubmit) {
          e.currentTarget.blur();
        }
        e.preventDefault();
      }
      if (e.key === 'ArrowUp') {
        onArrowUpKeyDown(e);
        if (!allowOnChangeWithKeys) {
          e.preventDefault();
        }
      }
      if (e.key === 'Backspace') {
        onBackSpaceKeyDown(e);
        if (!allowOnChangeWithKeys && !fireOnChangeOnBackspace) {
          e.preventDefault();
        }
      }
      if (e.key === 'ArrowDown') {
        onArrowDownKeyDown();
        if (!allowOnChangeWithKeys) {
          e.preventDefault();
        }
      }
      if (e.key === 'Tab' && retainFocusOnTab) {
        e.preventDefault();
        onInputBlur(e.currentTarget.value);
      }
    };

    const getTextArea = (): ReactElement => {
      return (
        <textarea
          ref={ref as Ref<HTMLTextAreaElement>}
          id={id}
          placeholder={useEmptyPlaceholder ? '' : placeholder || getDefaultPlaceholders()}
          className={getInputClasses()}
          disabled={isDisabled}
          value={isControlled ? input : inputState}
          maxLength={enforceMaxLength ? maxLength : undefined}
          readOnly={isReadOnly}
          title={elementTitle}
          onBlur={(e): void => {
            if (stopClickPropagation) {
              e.stopPropagation();
            }
            return onInputBlur(inputType === InputFieldType.NUMBER ? Number(e.target.value) : e.target.value);
          }}
          onFocus={(e): void => {
            if (stopClickPropagation) {
              e.stopPropagation();
            }
            if (selectOnFocus) {
              e.target.select();
            }
            return onInputFocus(inputType === InputFieldType.NUMBER ? Number(e.target.value) : e.target.value);
          }}
          onChange={(e): void => {
            maintainCursorPoisition(e);
            onInputChange(inputType === InputFieldType.NUMBER ? Number(e.target.value) : e.target.value);
            if (!isControlled) {
              setInputState(inputType === InputFieldType.NUMBER ? Number(e.target.value) : e.target.value);
            }
            handleTypingStoppedHandler(inputType === InputFieldType.NUMBER ? Number(e.target.value) : e.target.value);
          }}
          onKeyDown={(e): void => {
            if (e.key === 'Enter') {
              onInputSubmit();
            }
          }}
          onClick={(e): void => {
            if (stopClickPropagation) {
              e.stopPropagation();
            }
          }}
          rows={maxNumberOfRows}
        />
      );
    };

    const maintainCursorPoisition = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
      const caret = e.target.selectionStart;
      const element = e.target;
      window.requestAnimationFrame(() => {
        element.selectionStart = caret;
        element.selectionEnd = caret;
      });
    };

    const getInputField = (): ReactElement => {
      return (
        <input
          ref={ref as Ref<HTMLInputElement>}
          id={id}
          placeholder={useEmptyPlaceholder ? '' : placeholder || getDefaultPlaceholders()}
          className={getInputClasses()}
          disabled={isDisabled}
          value={isControlled ? input : inputState}
          maxLength={enforceMaxLength ? maxLength : undefined}
          readOnly={isReadOnly}
          type={inputType}
          title={elementTitle}
          onBlur={(e): void => {
            if (stopClickPropagation) {
              e.stopPropagation();
            }
            return onInputBlur(inputType === InputFieldType.NUMBER ? Number(e.target.value) : e.target.value);
          }}
          onFocus={(e): void => {
            if (stopClickPropagation) {
              e.stopPropagation();
            }
            if (selectOnFocus) {
              e.target.select();
            }
            return onInputFocus(inputType === InputFieldType.NUMBER ? Number(e.target.value) : e.target.value);
          }}
          onChange={(e): void => {
            if (inputType !== InputFieldType.NUMBER) {
              maintainCursorPoisition(e);
            }
            onInputChange(inputType === InputFieldType.NUMBER ? Number(e.target.value) : e.target.value);
            if (!isControlled) {
              setInputState(inputType === InputFieldType.NUMBER ? Number(e.target.value) : e.target.value);
            }
            handleTypingStoppedHandler(inputType === InputFieldType.NUMBER ? Number(e.target.value) : e.target.value);
          }}
          onKeyDown={(e): void => {
            if (e.key === 'Enter') {
              onInputSubmit();
              if (doBlurOnSubmit) {
                e.currentTarget.blur();
              }
              e.preventDefault();
            }
            if (e.key === 'ArrowUp') {
              onArrowUpKeyDown(e);
              if (!allowOnChangeWithKeys) {
                e.preventDefault();
              }
            }
            if (e.key === 'ArrowDown') {
              onArrowDownKeyDown();
              if (!allowOnChangeWithKeys) {
                e.preventDefault();
              }
            }
            if (e.key === 'Tab' && retainFocusOnTab) {
              e.preventDefault();
              onInputBlur(e.currentTarget.value);
            }
          }}
          onKeyUp={onKeyUp}
          onClick={(e): void => {
            if (stopClickPropagation) {
              e.stopPropagation();
            }
          }}
          {...(inputType === InputFieldType.NUMBER && {min})}
          {...(inputType === InputFieldType.NUMBER && {max})}
          {...(inputType === InputFieldType.NUMBER && {step})}
          {...(props.autoFocus && {autoFocus: true})}
        />
      );
    };

    return (
      <>
        {getLabel()}
        <div className={getContainerClassesForParentContainer()}>
          {getIconForInputField()}
          {isMultiline ? getTextArea() : getInputField()}
          {getControls()}
        </div>
        {getInformationField()}
      </>
    );
  }
);
