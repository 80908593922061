import React, {ReactElement} from 'react';
import {findGridItem, GRID_ID} from '@Libraries/email-marketing-campaigns-audience-library';
import {AudienceGridItemFooter} from '@Components/base-grid/components/grid-email-campaign-audience-item/components/audience-grid-item-footer';
import {AudienceGridItemContent} from '@Components/base-grid/components/grid-email-campaign-audience-item/components/audience-grid-item-content';
import styles from './grid-email-campaign-audience-item.module.scss';
import {useAppSelector} from '@/hooks';
import {AudienceGridItemMobile} from "@Components/base-grid/components/grid-email-campaign-audience-item/components/audience-grid-item-mobile";

interface GridEmailCampaignAudienceItemProps {
  id: number;
}

export interface GridEmailCampaignAudienceItemData {
  name: string;
  id: number;
  numAudiences: number;
  audiences: Array<GridEmailCampaignAudienceItemEmailsData>;
  isSpam: boolean;
  lastViewed: number;
  numNewAudiences: number;
}

export interface GridEmailCampaignAudienceItemEmailsData {
  email: string;
  fullName?: string;
  phoneNumber?: string;
  id: number;
  state: number;
  displayableBounceReason: string;
}

export function GridEmailCampaignAudienceItem({...props}: GridEmailCampaignAudienceItemProps): ReactElement {
  const grid = useAppSelector((state) => {
    return state.grids.gridsHashmap[GRID_ID];
  });
  const gridItem = findGridItem(grid, props.id);

  return (
    <li className="flexbox _unpadded">
      <div className={`flex-v-row ${styles.audienceGridItem} _unpadded audience-grid-item-large hidden-phone _full-width`}>
        <AudienceGridItemContent gridItem={gridItem} />
        <AudienceGridItemFooter gridItem={gridItem} />
      </div>
      <div className="_full-width visible-phone flexbox">
        <AudienceGridItemMobile gridItem={gridItem} />
      </div>
    </li>
  );
}
