import type {GridAudienceItemStorage, AddAudienceSuccessResponse} from '@Components/email-marketing-campaign-audience/components/audience-grid';
import type {GridEmailCampaignAudienceItemData} from '@Components/base-grid/components/grid-email-campaign-audience-item';
import type {GridItemStorage} from '@Components/base-grid/components/grid-item';
import type {GridStorageTypes} from '@Components/base-grid';
import type {MailingList} from '@Components/email-marketing-campaign-audience/email-marketing-campaign-audience-reducer';
import type {MailingListsSignUpFormCountResponse} from '@Hooks/email-marketing-campaign/useMailingListAndSignupForms';
import {PILL_THEME} from '@Components/pill';
import type {ContainerQueryObject} from '@Hooks/useContainerQuery';

export const GRID_ID = 'audienceGrid';

export const LAZY_LOAD_EMAILS_BATCH_SIZE = 30;

export const SPAM_SCORE_THRESHOLD = 0.2;

export const DEFAULT_MAILING_LIST_ID = -1;

export enum AudienceEmailState {
  EMAIL_STATE_VALID = 0,
  EMAIL_STATE_INVALID = 1,
  EMAIL_STATE_UNSUBSCRIBED = 3,
  EMAIL_STATE_COMPLAINT = 6,
  EMAIL_STATE_BOUNCE = 7,
}

export const audienceContainerQuery: ContainerQueryObject = {
  small: {
    minWidth: 0,
    maxWidth: 700,
  },
  medium: {
    minWidth: 700,
    maxWidth: 1000,
  },
  large: {
    minWidth: 1000,
  },
};

export enum ErrorCode {
  SPAM_LIST = 'spam-list',
}

export type FilterStates = {
  [key in AudienceEmailState]?: boolean;
};

export const findGridItem = (grid: GridStorageTypes, gridItemId: number) => {
  return grid.items.find((item: GridItemStorage) => {
    return parseInt(item.id, 10) === gridItemId;
  }) as GridAudienceItemStorage;
};

export const getEmailStatesEnumValues = (): Array<AudienceEmailState> => {
  return Object.values(AudienceEmailState).filter((v) => {
    return !isNaN(Number(v));
  }) as Array<AudienceEmailState>;
};

export const getAllEnabledFilters = (): FilterStates => {
  return getEmailStatesEnumValues().reduce((obj, state) => {
    return {...obj, [state]: true};
  }, {});
};

export const getAllDisabledFilters = (): FilterStates => {
  return getEmailStatesEnumValues().reduce((obj, state) => {
    return {...obj, [state]: false};
  }, {});
};

export const areAllFiltersChecked = (filters: FilterStates): boolean => {
  return Object.values(filters).every((item) => {
    return item;
  });
};

export const getDisplayableEmailState = (emailState: AudienceEmailState): string => {
  switch (emailState) {
    case AudienceEmailState.EMAIL_STATE_VALID:
      return window.i18next.t('pmwjs_valid');
    case AudienceEmailState.EMAIL_STATE_INVALID:
      return window.i18next.t('pmwjs_invalid');
    case AudienceEmailState.EMAIL_STATE_UNSUBSCRIBED:
      return window.i18next.t('pmwjs_unsubscribed');
    case AudienceEmailState.EMAIL_STATE_COMPLAINT:
      return window.i18next.t('pmwjs_complaint');
    case AudienceEmailState.EMAIL_STATE_BOUNCE:
      return window.i18next.t('pmwjs_bounce');
    default:
      return '';
  }
};

export const getPillThemeByEmailState = (emailState: AudienceEmailState): PILL_THEME => {
  switch (emailState) {
    case AudienceEmailState.EMAIL_STATE_VALID:
      return PILL_THEME.SUCCESS;
    case AudienceEmailState.EMAIL_STATE_INVALID:
    case AudienceEmailState.EMAIL_STATE_UNSUBSCRIBED:
    case AudienceEmailState.EMAIL_STATE_COMPLAINT:
    case AudienceEmailState.EMAIL_STATE_BOUNCE:
      return PILL_THEME.DANGER;
    default:
      return PILL_THEME.SUCCESS;
  }
};

export const getDisplayableEmailStateTooltip = (emailState: AudienceEmailState): string => {
  switch (emailState) {
    case AudienceEmailState.EMAIL_STATE_VALID:
      return window.i18next.t('pmwjs_valid_tooltip');
    case AudienceEmailState.EMAIL_STATE_INVALID:
      return window.i18next.t('pmwjs_invalid_tooltip');
    case AudienceEmailState.EMAIL_STATE_UNSUBSCRIBED:
      return window.i18next.t('pmwjs_unsubscribed_tooltip');
    case AudienceEmailState.EMAIL_STATE_COMPLAINT:
      return window.i18next.t('pmwjs_complaint_tooltip');
    case AudienceEmailState.EMAIL_STATE_BOUNCE:
      return window.i18next.t('pmwjs_bounce_tooltip');
    default:
      return '';
  }
};

export const onAddAudience = (successResponse: AddAudienceSuccessResponse, addItemsCallback: (audienceData: GridEmailCampaignAudienceItemData) => void) => {
  const data = successResponse?.data;
  const audienceData = data?.audienceData;

  if (audienceData) {
    addItemsCallback(audienceData);

    window.PMW.closeModalDialog('add-mailing-list-emails');
  }
};

export const onClickAddAudienceBtn = (onAddAudienceSuccess: (onAddAudienceSuccess: AddAudienceSuccessResponse) => void) => {
  window.PMW.util.require(typeof window.PMW.emailAudiences === 'undefined', 'emailAudiences', true, () => {
    window.PMW.emailAudiences.openAddMailingListDialog(null, onAddAudienceSuccess);
  });
};

export const getAudienceUrl = (uri = ''): string => {
  return window.PMW.util.site_url(`posters/mine#/email-marketing-campaign-audience/${uri}`);
};

export const castIdToStringForBaseGrid = (id: number): string => {
  return id.toString();
};

export const getMailingListIdFromUrlHash = (): number | undefined => {
  return parseInt(window.location.hash.split('/')[2], 10);
};

export const downloadCSV = (emailStrings: Array<string>, fileName = 'download') => {
  const csvContent = `data:text/csv;charset=utf-8,${emailStrings.join('\n')}`;
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');

  link.setAttribute('href', encodedUri);
  link.setAttribute('download', `${fileName}.csv`);
  document.body.appendChild(link);
  link.click();
};

export const removeContentLoadingState = (): void => {
  $('#content').removeClass('-loading');
};

export const showContentLoadingState = (): void => {
  $('#content').addClass('-loading');
};

export const areAllEmailsLoadedForMailingList = (mailingListData: MailingList): boolean => {
  return mailingListData && mailingListData.totalEmailsCount === mailingListData.emailIds.length;
};

export const getErrorMessage = (code: string): string => {
  switch (code) {
    case ErrorCode.SPAM_LIST:
      const spamScorePercentage = `${SPAM_SCORE_THRESHOLD * 100}%`;
      return window.i18next.t('pmwjs_mailing_list_spam_audience', {percentage: spamScorePercentage});
    default:
      return window.i18next.t('pmwjs_error_message');
  }
};

export const attachScrollListener = (onScrolledToBottom: VoidFunction): void => {
  const contentJquery = $(window);
  const documentJquery = $(document);

  contentJquery.on(
    'scroll',
    window.PMW.debounce(() => {
      const contentHeight = contentJquery.height();
      const documentHeight = documentJquery.height();
      const contentScrollTop = contentJquery.scrollTop();

      if (contentScrollTop === undefined || contentHeight === undefined || documentHeight === undefined) {
        return;
      }

      if (contentScrollTop + contentHeight > documentHeight - 200) {
        onScrolledToBottom();
      }
    }, 100)
  );
};

export const getMailingListsSignUpFormCount = async (): Promise<MailingListsSignUpFormCountResponse> => {
  return (await window.PMW.readLocal('emailmarketing/getMailingListsSignUpFormsCountForUser')) as MailingListsSignUpFormCountResponse;
};
